import React from "react";

const MainGame = ({ teamALogo, teamBLogo, teamAName, teamBName }) => {
  return (
    <div className='text-white'>
      <div className='grid grid-cols-2 gap-5 text-center font-bold'>
        <div>
          <img
            className='rounded-lg shadow-lg'
            src={teamALogo}
            alt={teamAName}
          />
        </div>
        <div>
          <img
            className='rounded-lg shadow-lg'
            src={teamBLogo}
            alt={teamBName}
          />
        </div>
      </div>
    </div>
  );
};

export default MainGame;
