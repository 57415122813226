import React from "react";
import FuturGame from "./FuturGame";
import MainGame from "./MainGame";
import TicketmasterEventsContainer from "./TicketmasterEventsContainer";

function App() {
  const { data, status, isLoading, error } = TicketmasterEventsContainer({
    attractionId: process.env.REACT_APP_TICKET_MASTER_ATTRACTION_ID,
    keyword: process.env.REACT_APP_TICKET_MASTER_KEYWORDS,
    locale: "en",
  });

  if (isLoading) return null;

  const futureEvents = getFutureEvents(data);

  if (!futureEvents)
    return (
      <div className='flex items-center justify-center h-screen'>
        No future events
      </div>
    );

  if (status === "error") return <div>Error: {error.message}</div>;

  const futureEvent = futureEvents[0]; // The first future event

  return (
    <div className='mx-auto rounded-xl overflow-hidden md:max-w-2xl my-9'>
      <div className='m-2'>
        <MainGame
          time={futureEvent.dates.start.dateTime}
          name={futureEvent.name}
          location={futureEvent._embedded.venues[0].name}
          teamALogo={
            futureEvent._embedded.attractions[0].images.find(
              (image) => image.height === 360
            ).url
          }
          teamBLogo={
            futureEvent._embedded.attractions[1].images.find(
              (image) => image.height === 360
            ).url
          }
          teamAName={futureEvent._embedded.attractions[0].name}
          teamBName={futureEvent._embedded.attractions[1].name}
        />
        {futureEvents.map((event, id) => (
          <FuturGame
            key={id}
            teamA={event._embedded.attractions[0].name}
            teamB={event._embedded.attractions[1]?.name ?? "TBD"}
            location={event._embedded.venues[0].name}
            date={event.dates.start.localDate}
            time={event.dates.start.localTime}
            url={event.url}
          />
        ))}
      </div>
    </div>
  );
}

function getFutureEvents(data) {
  const currentDate = new Date();
  return data._embedded.events.filter((event) => {
    const eventDate = new Date(event.dates.start.dateTime);
    eventDate.setHours(23, 59, 59);
    return eventDate > currentDate;
  });
}

export default App;
