import { useQuery } from 'react-query';

// Container component
function TicketmasterEventsContainer({ attractionId, keyword, locale }) {

  const fetchEvents = async () => {
    const response = await fetch(`https://app.ticketmaster.com/discovery/v2/events.json?attractionId=${attractionId}&apikey=${process.env.REACT_APP_TICKET_MASTER_API_KEY}&keyword=${keyword}&sort=date,asc&locale=${locale}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  return useQuery('events', fetchEvents);
}

export default TicketmasterEventsContainer;